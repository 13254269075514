<template>
  <div class="d-flex roundbox" :class="resData.status == 1 ? 'bg-danger' : ''">
    <!-- 对战状态 -->
    <div class="d-flex roundstatus" style="font-size:16px;">
      <div v-if="resData.status == 0">
        <span class="" style="font-size:12px;">
          <i class="el-icon-loading mb-2"></i>正在等待
        </span>
      </div>
      <div v-if="resData.status == 1">
        <span style="font-size:12px;">正在进行中</span>
      </div>
      <div v-if="resData.status == 2">
        <span style="font-size:12px;">对战结束</span>
      </div>
      <!-- 回合数 -->
      <div style="font-size:12px;">{{ resData.game_arena_box.length }} 回 合</div>
    </div>
    <div class="roundprice" :class="resData.status == 2 ? '' : ''">
      <!-- 价格 -->
      <div style="width: 100%; font-size: 12px">
        <money /> {{ resData.total_bean }}
      </div>
    </div>

    <!-- 头像 -->
    <div class="d-flex roundimg" :class="resData.status == 2 ? '' : ''">
      <div class="d-flex roundhimg">
        <div
          class="text-center"
          style="width: 30px; height: 30px"
          v-for="(user, i) in resDataUser"
          :key="i + 'b'"
        >
          <img
            v-if="user"
            class="rounded-circle border"
            width="30px"
            height="30px"
            :src="user.user.avatar || require('@/assets/img/pk/user.png')"
            alt=""
          />
          <img
            v-else
            :src="require('@/assets/img/add_home.png')"
            class="rounded-circle"
            width="30px"
            height="30px"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 按钮 -->
    <div :class="resData.status == 2 ? '' : ''" class="d-flex roundbnt active" style="font-size:16px;">
      <div class="headbg">
        <router-link
          v-if="resData.status == 2"
          :to="'/active/mysteryBox/' + resData.id"
          :active-class="'pk-conter-active'"
        >
          对战结束
        </router-link>
        <router-link
          v-if="resData.status == 0"
          :to="'/active/mysteryBox/' + resData.id + '?underway=0&round=0'"
          :active-class="'pk-conter-active'"
        >
          加入
        </router-link>
        <router-link
          v-if="resData.status == 1"
          :to="
            '/active/mysteryBox/' +
            resData.id +
            '?underway=1' +
            '&round=' +
            resData.ceil
          "
          :active-class="'pk-conter-active'"
        >
          进行观战
        </router-link>
      </div>
    </div>
    <!-- 箱子 -->
    <div class="d-flex roundboxs" ref="box" :class="resData.status == 2 ? '' : ''">
      <div ref="widthBox" class="d-flex bigbox">
        <div
          v-for="(box, bi) in resData.game_arena_box"
          :class="resData.status == 1 && resData.ceil == bi ? 'active' : ''"
          :key="bi + 'box'"
        >
          <img
            width="45px"
            :src="
              box.box
                ? box.box.intact_cover
                : require('@/assets/img/f_new/case-bg.png')
            "
            alt=""
          />
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indexPKListPhone",
  props: {
    resData: {
      type: Object,
      default() {
        return {};
      },
    },
    PKarenaListIndex: { type: Number, default: 0 },
  },
  data() {
    return {};
  },
  computed: {
    PKarenaListIndexLinde() {
      return this.PKarenaListIndex;
    },
    resDataUser() {
      let arrIndex = [];
      arrIndex.length = this.resData.user_num;
      for (
        let index = 0;
        index < this.resData.game_arena_player.length;
        index++
      ) {
        let arrDataIndex = this.resData.game_arena_player[index];
        arrIndex[arrDataIndex.seat] = arrDataIndex;
      }
      return arrIndex;
    },
    resDataItem: {
      get() {
        return this.resDataItem.game_arena_box.map((item) => {
          return item;
        });
      },
      set(val) {
        return val;
      },
    },
  },
  watch: {
    zizeng: {
      handler(newVal) {
        this.zizeng = newVal;
      },
      immediate: true, //页面监听开始的时候就直接调用：见上文解释
      deep: true, //见上文解释
    },
    resData: {
      handler(newVal) {
        this.resData = newVal;
      },
      immediate: true, //页面监听开始的时候就直接调用：见上文解释
      deep: true, //见上文解释
    },
  },
  mounted() {},
  created() {},
  beforeDestroy() {
    this.zizeng = 0;
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.roundbox {
  box-shadow: 0 0 30px 20px #89c33ea6 inset;
  flex-wrap: wrap;
  padding-top: 10px;
  border-radius: 15px;
  height: 235px;
  overflow: hidden;
  border: 3px solid #89c33e;
}
.roundstatus {
  width: 100%;
  justify-content: space-between;
}
.roundprice {
  width: 100%;
  text-align: center;
}
.roundimg {
  width: 100%;
}
.roundhimg {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
}
.roundbnt {
  width: 100%;
  justify-content: center;
}
.headbg {
  background-size: 100% 100%;
  text-align: center;
  border: 1px solid #81ba43;
  box-shadow: 0 0 15px -3px #81ba43 inset;
  height: 30px;
  width: 80px;
  border-radius: 15px;
  line-height: 30px;
}
.bigbox{
  justify-content: space-around;
  overflow: hidden;
}
</style>
