<template>
    <div class="d-flex overflow-hidden justify-content-between content-item"
         :class="resData.status == 1?'bg-danger':''">
        <div  class="d-flex font-weight-bold" style="background-color: #28283b ">
            <div style="color: #7e2dc8" class="d-flex align-items-center justify-content-center flex-column text-center" v-if="resData.status == 0">
                <i class="el-icon-loading mb-2"></i>
                <span class="px-3" style="display: inline-flex;">
                    正在等待
                </span>
            </div>
            <div class="d-flex align-items-center justify-content-center flex-column text-center"
                 v-if="resData.status == 1">
                <span class="" style="display: inline-flex;">进行中</span>
            </div>
            <div class="d-flex align-items-center justify-content-center flex-column text-center"
                 v-if="resData.status == 2">
                <span class="px-3" style="display: inline-flex;">对战结束</span>
            </div>
        </div>
        <div class="d-flex" :class="resData.status ==2 ? '':''">
            <div class="content-user  d-flex justify-content-center align-items-center
            flex-wrap content-pk-list-item-user px-md-3"
                 style="">
                <div class="text-center mb-1 mx-2" style="width:50px;height: 50px"
                     v-for="(user,i) in resData.game_arena_player" :key="i + 'b'"
                     :class="user.success? 'active ' : ''">
                    <img  class="rounded-circle border" width="50px" height="50px"
                          :src="user.user.avatar || require('@/assets/img/pk/user.png')" alt="">
                   <div class="mt-2 text-overflow-1" style="font-size: 12px">{{user.user.name}}</div>
                </div>
                <div class="text-center mb-1 mx-md-2" style="width:50px;height: 50px"
                     v-for="(user,i) in (resData.user_num - resData.game_arena_player.length)" :key="i + 'a'"
                     :class="user.success? 'active ' : ''">
                    <img  class="rounded-circle" width="50px" height="50px"
                          :src="require('@/assets/img/add_home.png')" alt="">
                </div>
            </div>
        </div>

        <div ref="box" :class="resData.status ==2 ? '':''"
             class="text-center content-pk-list-item-gun">
            <div class="text-center" style="font-size: 12px">
                {{resData.game_arena_box.length}}回合
            </div>
            <div ref="widthBox" class="content-pk-list-item-gun-content d-flex overflow-auto">
                <div style="" v-for="(box,bi) in resData.game_arena_box "
                     :class="resData.status == 1 && resData.ceil == bi ? 'active' : '' "
                     :key="bi + 'box'" class="content-pk-list-item-gun-item">
                    <img width="100px"  :src="box.box ? box.box.intact_cover:require('@/assets/img/f_new/case-bg.png')" alt="">
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>

        <div class="d-flex" :class="resData.status ==2 ? '':''">

            <div style="width: 150px" class="content-user  d-flex justify-content-center align-items-center
            flex-wrap content-pk-list-item-user px-md-3">
                <money class="ml-2" color="#FFFFFF"/>
                {{resData.total_bean}}
            </div>


            <div :class="resData.status ==2 ? '':''"
                 class="d-flex justify-content-center align-items-center content-pk-conter-active active">
                <router-link v-if="resData.status == 2" :to="'/active/mysteryBox/' + resData.id"
                             :active-class="'pk-conter-active'"
                             class=" text-center f_btn ">
                    对战结束
                </router-link>
                <router-link v-if="resData.status == 0" :to="'/active/mysteryBox/' + resData.id + '?underway=0&round=0'"
                             :active-class="'pk-conter-active'"
                             class=" text-center f_btn rounded-pill">
                    打开
                </router-link>
                <router-link v-if="resData.status == 1"
                             :to="'/active/mysteryBox/' + resData.id + '?underway=1' + '&round=' +resData.ceil"
                             :active-class="'pk-conter-active'" class=" text-center f_btn rounded-pill">
                    进行观战
                </router-link>
            </div>

        </div>



    </div>
</template>

<script>


    export default {
        name: "indexPkList",
        props:{
          resData:{type:Object,default(){return{}}},
            PKarenaListIndex:{type:Number,default:0},
        },
        data() {
            return {
            }
        },
        computed:{
            PKarenaListIndexLinde(){
                return this.PKarenaListIndex
            },

        },
        watch:{
            zizeng: {
                handler(newVal) {
                    this.zizeng = newVal
                },
                immediate: true,
                deep: true,
            },
            resData: {
                handler(newVal) {
                    this.resData = newVal
                },
                immediate: true,
                deep: true,
            },

        },
        mounted() {
        },
        created() {

        },
        beforeDestroy(){
            this.zizeng = 0
        },
        methods:{
            leftUp(){
               
            },
        }
    }
</script>

<style scoped lang="scss">
    
    .f_btn_bg{

    }
    .content-pk-list-item-gun-content>.active{
        transform: scale(1.1);
        animation: flash 1s infinite ease-in-out ;
        animation-direction:alternate;
        color: var(--main-yellow);
    }
    .active:hover>a{
        display: block;
        /*color:var(--main-yellow) !important;*/
    }
    .content {
        &-item {
            @media (max-width:1200px) {
                display: block !important;
                width: 96%;
                left: 2%;
                margin: 0 auto;
            }
        }

        &-pk {
            &-list {
                &-item {
                    &-user {
                        width: 200px;
                        background: #191926;

                        &:hover {
                            /*color: var(--main-yellow);*/
                        }

                        ;

                        & .active {
                            position: relative;
                            background: #191926;

                            &::before {
                                content: url("../../../assets/img/success_pk_list.png");
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: -10px;
                                left: -20px;
                                transform: rotate(-45deg);
                            }
                        }

                        @media (max-width: 1200px) {
                            width: 80%;
                            justify-content: start !important;

                            & div {
                                width: 60px !important;
                                height: 60px !important;
                            }

                            & div img {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }

                    &-gun {
                        width: 1366px;
                        position: relative;
                        padding: 20px 0;
                        overflow: auto;
                        background-color: #28283b;

                        @media (max-width: 1600px) {
                            width: 1366px;
                        }

                        @media (max-width: 1366px) {
                            width: 1100px;
                        }

                        @media (max-width: 1200px) {
                            width: 768px;
                            border-bottom: 2px solid var(--main-bg);

                            & div {
                                margin: 0 !important;
                            }
                        }

                        @media (max-width: 960px) {
                            width: 500px;

                            & div {
                                margin: 0 !important;
                            }
                        }

                        & div {
                            margin: 0 5px;
                        }

                        &-left, &-right {
                            width: 10%;
                            font-size: 50px;
                            color: #FFFFFF;
                        }

                        &-left {
                            transform: rotate(90deg)
                        }

                        &-content {

                            & > div {
                                float: left;
                            }
                        }

                        &-right {
                            transform: rotate(-90deg)
                        }

                        &-item {
                            width: 112px;

                            @media (max-width: 1600px) {
                                width: 112px;
                            }

                            @media (max-width: 1366px) {
                                width: 100px;
                            }

                            @media (max-width: 1200px) {
                                width: 80px;
                            }

                            @media (max-width: 960px) {
                                width: 60px;
                            }
                        }
                    }
                }
            }


            &-conter-active {
                width: 230px;
                background-color: #191926;

                @media (max-width: 1200px) {
                    margin-left: auto;
                    width: 100px;
                    font-size: 12px !important;
                    border-left: 3px solid var(--main-bg);

                    & > a {
                        padding-right: 5px !important;
                        padding-left: 5px !important;
                    }
                }
            }
        }

        &-user {
            position: relative;
            padding: 20px 0;

            @media (max-width: 1200px) {
                display: flex !important;
                flex-wrap: nowrap !important;
                border-right: 0;
                border-left: 0;
            }

            & > div {
                @media (max-width: 1200px) {
                }
            }
        }

        @media (max-width: 1200px) {
            display: block !important;
            padding-bottom: 10px;
        }
    }
    @keyframes flash {
        to{
            opacity: .7;
            transform: scale(.94);
        }
        from{
            opacity: 1;
            transform: scale(1);
        }

    }
</style>
